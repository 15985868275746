// React imports

import { useCallback, useEffect, useState } from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports

const useWindowSize = () => {
  const [size, setSize] = useState<any>(null)

  const updateSize = useCallback(() => {
    setSize([window.outerWidth, window.outerHeight])
  }, [setSize])

  useEffect(() => {
    window.addEventListener('resize', (e) => updateSize(), false)
    return function cleanup() {
      document.removeEventListener('resize', (e) => updateSize(), false)
    }
  }, [updateSize])

  useEffect(() => {
    updateSize()
  }, [updateSize])

  if (!size) {
    return { windowWidth: 0, windowHeight: 0 }
  }

  return { windowWidth: size[0], windowHeight: size[1] }
}

export default useWindowSize
